.title-display-inline {
  display: -webkit-inline-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 150px;
  top: 7px;
}

.btn-add-product {
  position: relative;

  a {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    transition: all 0.2s linear;

    &:hover {
      color: var(--white) !important;
    }
  }

  .toggle-select {
    border-left: 1px solid #969696;
    border-radius: 6px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    background-color: var(--primary);
    color: var(--white);
    transition: all 0.2s linear;

    &:hover {
      background-color: #313aa5;
    }
  }
}

.menu-add-product {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;

  a {
    color: var(--black);

    &:hover {
      color: var(--black) !important;
      background-color: #d9d9d9;
    }
  }
}

.logo-product-sm {
  $size: 40px;
  width: $size;
  height: $size;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  aspect-ratio: 1/1;
}

span.product-name {
  line-height: 17px;
}

.filter {
  animation: SHOW-FILTER 0.5s linear;
}

.btn-cancel {
  color: #4285f4;
}

.striped-table {
  tbody {
    tr:nth-child(2n) {
      background-color: #f5f9fe;
    }
    tr {
      border-bottom: none;
      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      &:hover {
        background-color: #f0f0f0;
      }
      td {
        padding: 20px 0;
        border-bottom: none;
        border-top: none;
      }
    }
  }
}

.text-primary :hover {
  color: #1a25ab;
}

@keyframes SHOW-FILTER {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
