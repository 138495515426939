.dashboard {
  .content {
    .title {
      font-size: 20px;
      line-height: 24px;
    }

    .sub-title {
      color: rgba(0, 0, 0, 0.6);
    }

    .actions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 8px;

      .btn-action {
        padding-block: 12px;
        border: 1px solid currentColor;
        line-height: 15px;
        box-shadow: none !important;

        &.no-active {
          border-color: #c4c6c9;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

          &:hover {
            border-color: #1a25ab;
            background-color: #1a25ab;
            color: var(--white);
          }
        }
      }
    }
  }

  .content-wrap {
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }

  #best-seller-table {
    tbody tr:nth-child(even) {
      background-color: #f5f9fe;
    }

    th,
    td {
      font-size: 14px;
      line-height: 17px;
      padding-inline: 24px;
    }

    th {
      padding-top: 0;
      padding-bottom: 21.5px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    th {
      &:first-child::before {
        left: 24px;
        width: calc(100% - 24px);
      }
      &:last-child::before {
        right: 24px;
        width: calc(100% - 24px);
      }
    }

    td {
      padding-top: 27px;
      padding-bottom: 24px;
      border: none;
    }
  }

  .total-sales {
    &__list {
      display: grid;
      row-gap: 18px;
    }

    &__item {
      background: var(--white);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      border-left: 10px solid var(--primary);
      overflow: hidden;
      padding: 32px 32px 33px 26px;
      position: relative;

      .price {
        font-size: 60px;
        line-height: 73px;
        margin-bottom: 12px;
      }

      .title {
        font-size: 20px;
        line-height: 24px;
      }

      .chart-img {
        position: absolute;
        bottom: 0;
        right: 32px;
      }
    }
  }

  .sales-statistics {
    .chart-wrap {
      overflow: hidden;
    }

    .profit {
      * {
        white-space: nowrap;
      }

      .title {
        font-size: 20px;
        line-height: 24px;
      }

      .price {
        font-size: 50px;
        line-height: 61px;
      }

      .time {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  .refunds {
    .chart-wrap {
      $size: 240px;
      width: $size;
      height: $size;
      position: relative;

      canvas {
        position: relative;
        z-index: 1;
      }

      .total {
        position: absolute;
        top: 50%;
        left: calc(50%);
        transform: translate(-50%, -50%);
        z-index: 0;
        width: 65%;

        .title {
          font-size: 18px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.6);
        }

        .qty {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    .info {
      display: grid;
      grid-template-columns: repeat(4, auto);
      align-items: center;
      gap: 16px;

      .item {
        .rect {
          $size: 20px;
          width: $size;
          height: $size;
          border-radius: 5px;
          background-color: #dfe0ec;
          flex-shrink: 0;
        }

        .qty {
          font-size: 20px;
          line-height: 24px;
        }

        .desc {
          font-size: 14px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .dashboard {
    .refunds {
      .chart-wrap {
        $size: 160px;
        width: $size;
        height: $size;
      }
    }
  }
}
