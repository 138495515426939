.tabs {
  padding: 16px 24px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  .tab-wrapper {
    margin-top: 40px;
  }
  .page-status {
    position: relative;
    padding-left: 26px;
    &::after {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: currentColor;
      left: 0;
      top: 9px;
    }
  }
  &--item {
    position: relative;

    &.active {
      &::before {
        position: absolute;
        content: '';
        width: 80%;
        height: 4px;
        background-color: var(--primary);
        left: 50%;
        bottom: -16px;
        transform: translate(-50%, 0);
      }
    }
  }
}

.purchased-form {
  position: relative;
  top: -16px;
}

// table payment
.table-payment {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;

  tr:nth-child(odd) {
    background-color: #f1f1f1;
  }
}

// Invoice CSS
.ticket {
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;

  &--header {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      width: calc(100% - 48px);
      border-bottom: 1px dashed #eaeaea;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &_group {
      a {
        color: #4285f4;
      }
    }
  }

  &--body {
    position: relative;
    &::after,
    &::before {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 50% 50% 0 0 / 100% 100% 0 0;
      background-color: #f5f7fb;
    }
    &::after {
      top: -8px;
      left: -8px;
      transform: rotate(90deg);
    }
    &::before {
      top: -8px;
      transform: rotate(-90deg);
      right: -8px;
    }

    &__total {
      position: absolute;
      right: 24px;
      bottom: 18px;
      font-size: 18px;
      line-height: 22px;
      span {
        font-style: 18px;
        line-height: 22px;
      }
    }
  }
}

.receipt-icon {
  .circle {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
  }
}
// End Invoice CSS

// Information CSS
.cell-item {
  padding-left: 41px;
}

.note-textarea {
  resize: none !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 80px;
  max-width: 480px;
  outline: none;
}

.info-address {
  &-item {
    line-height: 24px !important;
  }
}

.shipping-table {
  background-color: #f5f7fb !important;
  border-bottom: none !important;
}
// End Information CSS

.table--item {
  &:hover {
    background-color: #f0f0f0 !important;
  }
  &:nth-child(2n) {
    background-color: #f5f9fe;
  }
  td {
    border: none;
  }
}
.backlink-icon {
  position: relative;
  left: -8px;
}
.info-table {
  overflow-x: auto;

  .sku-product,
  .option-product {
    font-size: 13px;
    line-height: 17px;
    font-style: italic;
  }

  thead {
    tr {
      td {
        border-right: none;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    }
    tr:last-child {
      border-bottom: none;
    }
    td {
      border-bottom: none;
    }
  }
}

.order-table {
  tbody {
    tr {
      border-bottom: none;
      &:first-child {
        border-top: none;
      }
      &:hover {
        background-color: #f0f0f0;
      }
      td {
        padding: 20px 0;
        border-bottom: none;
        border-top: none;
      }
    }
  }
}
.notes-wrapper {
  height: 50vh;
  max-height: 100vh;
  overflow-y: auto;
}
.schedule-wrapper {
  height: 50vh;
  max-height: 100vh;
  overflow-y: auto;
  .schedule-item {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid var(--primary);
      left: 0;
      top: 5px;
    }
    &:not(:first-child)::after {
      background-color: var(--primary);
    }
    &:not(:last-child)::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      background-color: var(--primary);
      border-radius: 5px;
      left: 3px;
      top: 14px;
    }
  }
}

.info-shipping {
  table {
    tbody {
      tr {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
        &:last-child {
          border: none;
        }
      }
    }
  }
}
.pills {
  padding: 8px 16px;
  border-radius: 20px;
  &-danger {
    background: rgba(237, 83, 72, 0.2);
    color: var(--danger);
  }
  &-success {
    background: rgba(127, 188, 2, 0.2);
    color: var(--success);
  }
  &-warning {
    background: rgba(255, 176, 98, 0.2);
    color: var(--warning);
  }
}

.tracks-table {
  thead {
    tr {
      background: rgba(192, 194, 194, 0.2);
      td {
        border-right: none !important;
      }
    }
  }
  tbody {
    tr {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 24px;
        width: calc(100% - 48px);
        height: 1px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
      }
      &:last-child {
        &::after {
          content: '';
          border-bottom: none;
        }
      }
    }
  }
}
.notes-title {
  margin-bottom: 24px;
}
.notes {
  margin-bottom: 24px;
  &--icon {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
  }
}

.close-icon {
  transition: color 0.2s linear;
  font-size: 24px;

  &:hover {
    color: var(--danger);
  }
}

.content-update {
  border-radius: 10px;
  width: 424px;
  max-width: 100%;
  max-height: calc(100vh - 24px);
  overflow: auto;

  .heading {
    .title {
      font-size: 24px;
      line-height: 34px;
    }

    .current-status {
      padding-left: 26px;
      position: relative;

      &::before {
        $size: 10px;
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: $size;
        height: $size;
        border-radius: 50%;
        background-color: currentColor;
      }
    }
  }

  .body {
    .comment {
      resize: none;
      height: 150px;
      border-radius: 5px;
      overflow: auto;
      transition: all 0.2s linear;

      &:focus {
        box-shadow: none;
        border-color: var(--primary);
      }
    }

    .select-new-status {
      height: 46px;
      background-position: calc(100% - 16px) 50%;

      option {
        color: #000;
      }
    }
  }

  .footer {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 8px));
    gap: 16px;

    .button {
      border-radius: 10px;
      height: 46px;
    }
  }
}

.time-at-comment {
  $space: 10px;
  padding-top: $space;
  margin-top: $space;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  width: fit-content;
}

@media screen and (max-width: 1440px) {
  .schedule-wrapper {
    margin-top: 24px;
  }
}
