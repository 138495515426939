.sign-up {
  .sub-title {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 17px;

    span {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .message {
    font-size: 14px;
    line-height: 17px;
  }

  .input-list.row {
    --bs-gutter-x: 20px;
    row-gap: 16px;
  }

  .terms {
    input {
      $size: 20px;
      width: $size;
      height: $size;
      box-shadow: 1px 1px 20px 2px rgba(0, 0, 0, 0.15);
      border-radius: 15px;
    }

    label {
      margin-left: 18px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;

  &__terms {
    position: relative;
    width: 710px;
    background-color: #fff;
    background: #ffffff;
    box-shadow: 1px 1px 20px 2px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    animation: showModal 0.15s linear;
  }

  &__title {
    padding-top: 30px !important;
    font-size: 35px;
    line-height: 43px;
    font-weight: 600;
  }

  &__sub-title {
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
  }

  &__content-wrap {
    padding-right: 30px;
    height: 540px;
    max-height: 60vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      margin-left: 15px;
    }
  }

  &__close-icon {
    $position: 24px;
    position: absolute;
    top: $position;
    right: $position;
    font-size: 22px;

    &:hover {
      opacity: 0.7;
    }
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.store-select {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  outline: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;

  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 14px;
  border: none;
  &.error {
    border: 1px solid var(--danger);
  }
}
