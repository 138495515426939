.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #000000;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  transform: translateY(-50%);
}

.custom-checkbox:hover input ~ .checkmark {
  border: 1px solid var(--primary);
  background-color: #ccc;
}

.custom-checkbox input:checked ~ .checkmark {
  border: 1px solid var(--primary);
  background-color: var(--primary);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
