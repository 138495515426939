.forgot-password {
  .sub-title {
    font-size: 14px;
    line-height: 17px;

    span {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .terms {
    input {
      $size: 20px;
      width: $size;
      height: $size;
      box-shadow: 1px 1px 20px 2px rgba(0, 0, 0, 0.15);
      border-radius: 15px;
    }

    label {
      margin-left: 18px;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .message {
    font-size: 14px;
    line-height: 17px;
  }
}
