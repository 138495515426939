.content-wrapper {
  padding-bottom: 32px;
}

.page-header {
  &--subtitle {
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.status {
  &--items {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-radius: 5px;
    padding: 22px;
    &.active {
      border: 1px solid var(--primary);
    }
  }
}
.table-status {
  font-size: 14px;
  line-height: 17px;
  &-danger {
    color: var(--danger);
  }
  &-warning {
    color: var(--warning);
  }
  &-success {
    color: var(--success);
  }
  &-primary {
    color: var(--primary);
  }
  &-info {
    color: var(--info);
  }
}

.table-section {
  overflow: hidden;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

.pills {
  padding: 8px 18px;
  border-radius: 20px;
  &-success {
    font-size: 12px;
    line-height: 15px;
    color: #7fbc02;
    font-weight: 500;
    background: rgba(127, 188, 2, 0.2);
  }

  &-danger {
    background: rgba(237, 83, 72, 0.2);
    font-size: 12px;
    line-height: 15px;
    color: #ed5348;
    font-weight: 500;
  }
}

.pages {
  font-size: 14px;
  line-height: 17px;

  .item-per-pages {
    margin: 0 16px;
    padding: 8px 20px 8px 10px;
    outline: none;
    border: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;

    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 6px;

    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
}

.filter-active {
  font-size: 14px;
  margin: 8px 15px 8px 0px;
  display: inline-flex;
}

.reset-filter-active {
  padding: 1px 3px !important;
}

.reset-filter {
  &:hover {
    color: #1a25ab !important;
  }
}

.btn-gray {
  background-color: rgb(172, 172, 172) !important;
  color: #fff !important;
  &:hover {
    background-color: gray !important;
  }
}

.filter-wrapper {
  .filters {
    &--button {
      background-color: var(--primary);
      // border-radius: 10px;
      &.export {
        padding: 13px 15px;
      }
      &.apply {
        padding: 13px 37px;
      }
    }
  }
}
.filter-board {
  .btn-group {
    span {
      color: #4285f4;
    }
    button {
      background: var(--primary);
      border-radius: 10px;
      margin-left: 32px;
      padding: 13px 36px;
    }
  }
}
.create-new-btn {
  background: #180767;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
}

.view-icon {
  &::before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="26" height="auto" viewBox="0 0 1080 1080" xml:space="preserve"><g transform="matrix(1 0 0 1 540 540)" id="d767d1cc-a2cb-4939-b946-c6191548ca94"><rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;" vector-effect="non-scaling-stroke"  x="-540" y="-540" rx="0" ry="0" width="26" height="26"/></g><g transform="matrix(1 0 0 1 540 540)" id="5a9f7262-1364-4785-a838-1956db7edb88"></g><g transform="matrix(41.54 0 0 41.54 540 540)"><path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(24,7,103); fill-rule: nonzero; opacity: 1;"  transform=" translate(-13, -10)" d="M 13 0 C 7.09091 0 2.00909 3.75758 0 9.09091 C 2.00909 14.4242 7.09091 18.1818 13 18.1818 L 14.3 18.1818 C 14.1818 17.8182 14.1818 17.4545 14.1818 16.9697 C 14.1818 16.4848 14.1818 16.1212 14.3 15.6364 C 13.8273 15.6364 13.4727 15.7576 13 15.7576 C 8.50909 15.7576 4.49091 13.2121 2.6 9.09091 C 4.49091 4.9697 8.50909 2.42424 13 2.42424 C 17.4909 2.42424 21.5091 4.9697 23.4 9.09091 C 23.2818 9.33333 23.0455 9.57576 22.9273 9.93939 C 23.7545 10.1818 24.4636 10.4242 25.1727 10.9091 C 25.5273 10.303 25.7636 9.69697 26 9.09091 C 23.9909 3.75758 18.9091 0 13 0 Z M 13 5.45455 C 10.9909 5.45455 9.45455 7.0303 9.45455 9.09091 C 9.45455 11.1515 10.9909 12.7273 13 12.7273 C 15.0091 12.7273 16.5455 11.1515 16.5455 9.09091 C 16.5455 7.0303 15.0091 5.45455 13 5.45455 Z M 21.2727 20 L 21.2727 17.5758 L 16.5455 17.5758 L 16.5455 15.1515 L 21.2727 15.1515 L 21.2727 12.7273 L 24.8182 16.3636 L 21.2727 20 Z" stroke-linecap="round" /></g></svg>');
  }
  &:hover {
    &::before {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="26" height="auto" viewBox="0 0 1080 1080" xml:space="preserve"><g transform="matrix(1 0 0 1 540 540)" id="d767d1cc-a2cb-4939-b946-c6191548ca94"><rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;" vector-effect="non-scaling-stroke"  x="-540" y="-540" rx="0" ry="0" width="26" height="26"/></g><g transform="matrix(1 0 0 1 540 540)" id="5a9f7262-1364-4785-a838-1956db7edb88"></g><g transform="matrix(41.54 0 0 41.54 540 540)"><path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(26, 37, 171); fill-rule: nonzero; opacity: 1;"  transform=" translate(-13, -10)" d="M 13 0 C 7.09091 0 2.00909 3.75758 0 9.09091 C 2.00909 14.4242 7.09091 18.1818 13 18.1818 L 14.3 18.1818 C 14.1818 17.8182 14.1818 17.4545 14.1818 16.9697 C 14.1818 16.4848 14.1818 16.1212 14.3 15.6364 C 13.8273 15.6364 13.4727 15.7576 13 15.7576 C 8.50909 15.7576 4.49091 13.2121 2.6 9.09091 C 4.49091 4.9697 8.50909 2.42424 13 2.42424 C 17.4909 2.42424 21.5091 4.9697 23.4 9.09091 C 23.2818 9.33333 23.0455 9.57576 22.9273 9.93939 C 23.7545 10.1818 24.4636 10.4242 25.1727 10.9091 C 25.5273 10.303 25.7636 9.69697 26 9.09091 C 23.9909 3.75758 18.9091 0 13 0 Z M 13 5.45455 C 10.9909 5.45455 9.45455 7.0303 9.45455 9.09091 C 9.45455 11.1515 10.9909 12.7273 13 12.7273 C 15.0091 12.7273 16.5455 11.1515 16.5455 9.09091 C 16.5455 7.0303 15.0091 5.45455 13 5.45455 Z M 21.2727 20 L 21.2727 17.5758 L 16.5455 17.5758 L 16.5455 15.1515 L 21.2727 15.1515 L 21.2727 12.7273 L 24.8182 16.3636 L 21.2727 20 Z" stroke-linecap="round" /></g></svg>');
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes SHOW-SEARCH {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
