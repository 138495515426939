.pagination-item.pagination {
  &-item {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 4px 11px;
    line-height: 20px !important;

    &:hover {
      transition: all 0.5s ease;
      background-color: #1a25ab !important;
      color: #fff !important;
    }

    &.active {
      background-color: rgba(0, 0, 128, 1) !important;
      color: #fff !important;
    }
  }
}
