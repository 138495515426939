.transit {
  height: 431px;
  max-width: 920px;
  background: transparent;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  &-item {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 70px;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 10px;
    min-width: 400px;
    width: 100%;
  }
  &-content {
    height: 130px;
  }
  .submit-button {
    color: #f9f9f9;
    padding: 12px 15px;
    border-radius: 5px;
    svg {
      padding-right: 14px;
    }
  }
  .esc-btn {
    position: absolute;
    top: 40px;
    right: 40px;
  }
}
