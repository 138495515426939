.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;

  .content-wrap {
    max-width: calc(100% - 24px * 2);
    max-height: calc(100% - 24px * 2);
    animation: showPopup 0.2s linear;
    border-radius: 5px;
    overflow-y: auto;
  }
}

@keyframes showPopup {
  from {
    transform: scale(0.6);
    opacity: 0.6;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
