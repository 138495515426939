.select.form-control {
  transition: all 0.2s linear !important;
  padding-block: 12px;
  padding-left: 12px;
  padding-right: 36px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  appearance: none;
  outline: none;
  background-image: url(./../../../app/images/arrow-down.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  flex-shrink: 0;
  font-weight: 300;
  width: 300px;
  line-height: 1.25rem;
  font-size: 1rem;

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    cursor: default;
    background-color: var(--disabled-color);
    transition: none !important;
  }
}
