#create-edit__product .cate-attr {
  .multi-select-wrap {
    max-width: 1000px;
  }

  .label-left {
    width: 135px;
  }

  .attr-list {
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
    gap: 24px;

    h3 {
      max-width: 200px;
    }

    select {
      max-height: 48px;
      margin-block: auto;
    }
  }

  .rct-node {
    list-style-type: none;

    &:last-child {
      padding-bottom: 0;
    }

    input {
      width: 16px;
      height: 16px;
    }
  }

  .rct-text {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    label {
      display: flex;
      align-items: center;
    }

    :not(button) ~ label {
      padding-left: 20px;
    }
  }

  .react-checkbox-tree > ol {
    margin-block: 24px;
  }

  .rct-collapse.rct-collapse-btn {
    border: none;
    background-color: transparent;
    padding: 0;
    padding-right: 8px;
  }

  .rct-title {
    margin-left: 12px;
  }
}

@media (max-width: 1279px) {
  #create-edit__product .cate-attr {
    .attr-list {
      gap: 16px;
      grid-template-columns: auto;
    }
  }
}
