.export {
  &-button {
    padding: 11px;
    border-radius: 10px;
    transition: all 0.2s linear;

    &:hover {
      color: var(--white) !important;
      background-color: var(--primary-hover) !important;
    }

    svg {
      position: relative;
      top: 0;
    }
  }
  &-select {
    padding: 8px 16px 8px 17px;
    outline: none;
    border: none;
    font-size: 16px;
    line-height: 20px;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;

    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 8px);
    background-position-y: 10px;

    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}

.report-tabs {
  &--items {
    color: #000;
    &.active {
      background: #f5f9fe;
      border-radius: 5px 5px 0px 5px;
      position: relative;
      overflow: hidden;
      color: var(--primary);
      &::after {
        position: absolute;
        content: '';
        width: 5px;
        height: 100%;
        background-color: var(--primary);
        left: 0;
        top: 0;
      }
    }
  }
}

.filter-item {
  position: relative;
  &-delete {
    position: absolute;
  }
}
.bg-total {
  background-color: #d9e7fd !important;
}
