// .filter-section {
//   input,
//   select {
//     padding: 11px 24px;
//     box-shadow: none;
//     outline: none;
//     border: 1px solid #ccc;
//     font-size: 14px;
//     &:-webkit-autofill {
//       box-shadow: 0 0 0 1000px white inset !important;
//       -webkit-box-shadow: 0 0 0 1000px white inset !important;
//     }

//     &:focus {
//       border-color: var(--primary) !important;
//     }
//   }
// }

.form-input__wrap {
  position: relative;

  .form-input-control,
  select {
    padding: 15px 24px;
    background: #ffffff;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    outline: none;
    border: 1px solid transparent;
    transition: all 0.2s linear;

    &.has-icon {
      padding-right: 48px;
    }

    &:focus {
      border-color: var(--primary);
    }

    // Change position of label
    &:not(:placeholder-shown) + label,
    &:focus + label {
      top: 0;
      left: 15px;
      padding-inline: 8px;
      background-color: #ffffff;
      z-index: 1;
      font-size: 12px;
      line-height: 15px;
      color: var(--primary);
    }

    &.input--error {
      border-color: var(--danger);
    }

    &.input--error:not(:focus, :placeholder-shown) + label {
      color: var(--danger);
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px white inset, 1px 1px 2px 2px rgba(0, 0, 0, 0.1) !important;
      -webkit-box-shadow: 0 0 0 1000px white inset, 1px 1px 2px 2px rgba(0, 0, 0, 0.1) !important;
      background-clip: content-box;
    }
  }

  .form-input-label {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.2s linear;
  }

  .form-input-control,
  .form-input-control:placeholder-shown,
  label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

  .input--icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
}

.input-message--error {
  font-size: 10px;
  line-height: 12px;
}

.form-control.form-control--custom {
  height: 46px;
  border-color: rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
  caret-color: var(--primary);
  transition: border-color 0.2s linear;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  &:focus {
    box-shadow: none;
    border-color: var(--primary) !important;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }

  &:disabled,
  &.disabled {
    --bs-secondary-bg: var(--disabled-color);
    transition: none !important;
    pointer-events: none;
    user-select: none;
  }

  // Style of input element
  &.form-control--custom-input {
    max-width: 700px;
  }

  // Style of textarea element
  &.form-control--custom-textarea {
    resize: none;
    min-height: 100px;
    max-width: 1000px;
  }
}
