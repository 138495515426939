.button-form {
  border-radius: 10px;
  outline: none;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s linear;
  background: linear-gradient(270deg, rgba(51, 204, 51, 0.71) 0%, rgba(0, 0, 128, 0.7) 54.69%, rgba(0, 0, 128, 0.88) 93.23%),
    linear-gradient(270deg, rgba(0, 0, 128, 0.5) 0%, rgba(0, 0, 128, 0) 46.35%);

  &:hover {
    opacity: 0.95;
  }

  &:disabled {
    opacity: 0.35;
    cursor: initial;
    pointer-events: none;
    user-select: none;
  }
}
