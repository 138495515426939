.ticket-wrap {
  .wrapper {
    border-radius: 5px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  }

  .ticket-input-time {
    border-radius: 5px;
    height: 46px;
  }

  .ticket-label-time {
    text-align: end;
  }

  .ticket-item {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    min-width: 500px;

    .heading {
      border-bottom: 1px solid #e7e7e7;
      .left {
        .status-color {
          $size: 22px;
          width: $size;
          height: $size;
        }

        .ticket-id {
          font-size: 20px;
          line-height: 1.5;
          color: #2e2c34;
        }

        .priority {
          outline: none;
          border: 1px solid #e22626;
          position: relative;
          color: #e22626;
          background-color: transparent;
          border-radius: 50rem;

          &::before {
            content: '';
            position: absolute;
            inset: 0;
            background-color: currentColor;
            // z-index: -1;
            opacity: 0.05;
          }
        }
      }
    }

    .unread {
      color: #4285f4 !important;
    }

    .body {
      .desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }

  .menu {
    .menu-list {
      column-gap: 42px;
      margin-bottom: 30px;
    }

    .menu-item {
      list-style: none;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: flex-end;
      column-gap: 12px;
      cursor: pointer;
      transition: color 0.2s linear;
      position: relative;

      &::before {
        $size-border: 3px;
        content: '';
        position: absolute;
        top: calc(100% - $size-border);
        left: 0;
        right: 0;
        height: $size-border;
        border-radius: 50rem;
        background-color: transparent;
        transition: background-color 0.2s linear;
      }

      &:hover,
      &.active {
        color: var(--primary);

        &::before {
          background-color: var(--primary);
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .ticket-wrap {
    .ticket-item {
      .desc {
        min-width: unset;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .ticket-wrap {
    .menu {
      .menu-list {
        column-gap: 24px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .ticket-wrap {
    .menu {
      .menu-list {
        column-gap: 24px;
      }

      .menu-item {
        .menu-icon {
          display: none;
        }
      }
    }
  }
}
