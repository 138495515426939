.order-status-filter {
  select {
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url('data:image/svg+xml;utf8,<svg fill=%27black%27 height=%2724%27 viewBox=%270 0 24 24%27 width=%2724%27 xmlns=%27http://www.w3.org/2000/svg%27><path d=%27M7 10l5 5 5-5z%27/><path d=%27M0 0h24v24H0z%27 fill=%27none%27/></svg>');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 6px;
  }
}

.search-btn {
  position: relative;
  width: 51px;
  height: 46px;
  border-radius: 50px;
  background: transparent;

  input[type='text'] {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 8px 48px;
    border: none;
    outline: none;
  }

  svg {
    position: absolute;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.6);
    &.reset-search {
      right: 10px;
    }
  }
}
