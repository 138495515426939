@import 'animation';
$enable-gradients: true;
$primary: #180767;
$secondary: #87bdd8;
$success: #7fbc02;
$info: #b7d7e8;
$danger: #ed5348;
$warning: #ffb062;
$dark: #000;
$white: #fff;
$thead: #c2ccd8;
$spacer: 1rem;
$primary-hover: #1a25ab;
$disabled: #f0eeee;
$input-btn-padding-x: 1rem;
$enable-negative-margins: true;

$custom-spacer: (
  12: $spacer * 0.75,
  28: $spacer * 1.75,
  32: $spacer * 2,
);

$custom-theme-colors: (
  thead: #c2ccd8,
);

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';

$spacers: map-merge($spacers, $custom-spacer);
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

// Custom utilities
@import '../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../node_modules/bootstrap/scss/maps';
@import '../../../node_modules/bootstrap/scss/utilities';

$custom-widths: (
  2: 2%,
  5: 5%,
  10: 10%,
  12-5: 12.5%,
  15: 15%,
  20: 20%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  70: 70%,
  150px: 150px,
  300px: 300px,
  420px: 420px,
  500px: 500px,
  fit-content: fit-content,
  unset: unset,
  initial: initial,
);

$utilities: map-merge(
  $utilities,
  (
    'width':
      map-merge(
        map-get($utilities, 'width'),
        (
          values: map-merge(map-get(map-get($utilities, 'width'), 'values'), $custom-widths),
        )
      ),
  )
);

@import '~bootstrap/scss/bootstrap.scss';

// Override animate react-loading-skeleton
.react-loading-skeleton {
  animation: changeColor 2s linear infinite;
}

.form-check-input {
  $size: 20px;
  box-shadow: none !important;
  width: $size;
  height: $size;
  flex-shrink: 0;
  border: 1px solid var(--dark) !important;
}

.form-check-label {
  font-size: 16px;
  line-height: 20px;
}

// Override toast
.swal2-container {
  z-index: 9999999 !important;
}

.swal2-popup {
  align-items: center !important;
}
