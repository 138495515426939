.login {
  .sub-title {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 17px;

    span {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .input-list {
    display: grid;
    row-gap: 16px;
  }

  .message {
    font-size: 14px;
    line-height: 17px;
  }
}
