img[alt='mobile_image'] {
  display: none !important;
}

#create-edit__product {
  .wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 40px;
  }

  .popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0px;
    z-index: 9999;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }

  .popup-content {
    width: 95%;
    height: 95%;
    overflow: auto;
    position: relative;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .checkbox-custom {
    input {
      $size: 20px;
      width: $size;
      height: $size;

      &:checked {
        accent-color: var(--primary);
      }

      &:disabled {
        cursor: default;
      }
    }

    label.disabled {
      cursor: default;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .heading-text {
    font-size: 20px;
    line-height: 24px;
    transition: color 0.2s linear;
    width: fit-content;

    svg {
      font-size: 18px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
