.button {
  outline: none;
  border: none;
  padding: 15.5px 24px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  background-color: var(--primary);
  color: var(--white);

  &:not(:disabled) {
    transition: all 0.2s linear;
  }

  &:disabled {
    background: rgba(0, 0, 128, 0.2);
  }

  &.outline {
    background-color: var(--white);
    color: var(--primary);
    border: 1px solid var(--primary);
  }

  &:not(:disabled):hover {
    background-color: #1a25ab;
    color: var(--white);
  }
}
