$header-height: 141px;

.triangle {
  position: relative;
}

.username {
  max-width: 240px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.header-fixed {
  position: fixed;
  width: 100%;
  height: $header-height;
  top: 0;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%);
  background-color: #f5f7fb;
}

.logo {
  $size: 84px;
  border-radius: 50%;
  width: $size;
  height: $size;
  object-fit: cover;
}

.logo-sm {
  $size: 55px;
  width: $size;
  height: $size;
  object-fit: cover;
}

.page-title {
  margin-top: calc($header-height + 24px);
  margin-bottom: 24px;
  font-size: 35px;
  line-height: 43px;
}

.header {
  height: fit-content;

  &-wrapper {
    height: 100%;
    padding-block: 40px;
    position: relative;
    padding-inline: 212px;
  }

  &__features {
    &--settings {
      margin-right: 24px;
      width: 50px;
      height: 50px;
      color: rgb(102, 102, 102);
    }
  }

  .tooltips {
    animation: DISPLAY-MENU 0.5s ease;
    position: absolute;
    top: calc(100% + 10px);
    right: 0px;
    width: calc(100% + 20px);
    width: 180px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 1;
    margin-bottom: 32px;

    .icon-wrap {
      width: 32px;
      text-align: left;
    }

    .logout-btn {
      background-color: #000;
      font-size: 13px;
      line-height: 16px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.banner {
  position: relative;
  min-height: 218px;
  width: 100%;
  background-image: url(./../../../../app/images/banner.png);
  background-size: 100% 100%;
  top: 123px;
  margin-bottom: 147px;

  &--date {
    position: absolute;
    bottom: 40px;
    right: 24px;
  }

  &--greeter {
    font-size: 35px;
    line-height: 43px;
    animation: GO-BIGGER 0.5s ease;
  }

  &--current-page {
    position: absolute;
    bottom: 40px;
    left: 24px;
  }
  &--store {
    position: absolute;
    top: 36px;
    left: 24px;
  }
}

@keyframes GO-BIGGER {
  0% {
    font-size: 12px;
    line-height: 16px;
  }

  100% {
    font-size: 35px;
    line-height: 43px;
  }
}

@keyframes DISPLAY-MENU {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navigator {
  .item-wrapper {
    position: relative;
    animation: DISPLAY-MENU 0.5s ease;

    svg.nav-item-icon--ticket {
      display: inline-block;
      height: 100%;
      width: 24px;
    }

    &.active {
      a,
      svg {
        color: var(--primary) !important;
      }
    }
    &:hover {
      transition: all 0.5s linear;
      a,
      svg {
        color: #1a25ab !important;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .navigator {
    &--items {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1280px) {
  .navigator {
    .item-wrapper {
      margin-inline: 16px !important;
    }
  }

  .header {
    &__features {
      &--settings {
        margin-right: 16px;
      }
    }
  }
}

@media (max-width: 1200px) {
  $header-height: 114px;

  .header-fixed {
    height: $header-height;
  }

  .content-margin {
    height: calc($header-height + 48px);
  }

  .header__logo img {
    width: auto;
    max-width: 160px;
  }

  p.store-current,
  .navigator .item-wrapper,
  .username {
    font-size: 14px !important;
    line-height: 17px !important;
  }

  .header__features--settings,
  .header__features--avatar {
    img {
      width: 48px;
      height: 48px;
    }
  }
}
