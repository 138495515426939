.app-container {
  width: 100%;
  padding-inline: 212px;
}

@media screen and (max-width: 1440px) {
  .app-container {
    padding: 0 80px;
    overflow: hidden;
  }
  .header-wrapper {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 100%;
    overflow-x: auto;
  }
}
