#create-edit__product .img-video {
  padding-inline: 64px !important;

  .title {
    white-space: nowrap;
    width: 140px;
  }

  .video-content {
    .title {
      width: 130px;
      flex-shrink: 0;
      height: fit-content;
      margin-bottom: 0;

      &.center {
        margin-top: 23px;
        transform: translateY(-50%);
      }
    }
  }

  .video-wrap {
    flex-grow: 1;
    max-width: 640px;
    height: 360px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    svg {
      font-size: 40px;
      color: #ccc;
    }
  }

  .video {
    z-index: 1;
  }

  .image-wrap {
    position: relative;

    div {
      position: absolute;
      top: calc(50% + 2px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #ccc;
    }
  }

  .image-large {
    object-fit: contain;
    width: 40%;
    height: auto;
    max-height: 600px;
  }

  .list-role {
    width: 400px;
  }

  .close-icon {
    font-size: 26px;
    transition: color 0.2s linear;

    &:hover {
      color: var(--danger);
    }
  }

  .done-wrap {
    color: var(--primary);
    position: relative;

    .button {
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: currentColor;
      opacity: 0.05;
    }
  }

  textarea {
    outline: none;
    resize: none;
    width: 400px;
    height: 120px !important;
    transition: border 0.2s linear;
    border: 1px solid rgba(0, 0, 0, 0.2);

    &:focus {
      border-color: var(--primary);
    }
  }

  .item-select {
    position: relative;
    width: 150px;

    .remove {
      position: absolute;
      top: 8px;
      right: 8px;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .image {
    width: 100%;
    height: 150px;
    object-fit: cover;

    .preview {
      height: unset;
      max-height: 200px;
    }
  }

  .upload {
    background: #ffffff;
    border: 1px dashed #4285f4;
    border-radius: 5px;
    $size: 150px;
    width: $size;
    height: $size;

    .upload-img {
      object-fit: contain;
      color: #ccc;
    }

    span {
      color: rgba(0, 0, 0, 0.2);
    }
  }
}
