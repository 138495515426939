.overview-item {
  padding-block: 22px;
  border-radius: 5px;
  row-gap: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

  .qty {
    font-size: 50px;
    line-height: 61px;
  }

  .title {
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #fff !important;
      text-decoration: underline !important;
    }
  }

  .sub-title {
    font-size: 14px;
    line-height: 17px;
  }

  .icon {
    font-size: 30px;
    position: relative;
    top: -2px;

    &.move-top {
      top: -5px;
    }
  }
}
