@import 'base';

.fs-14 {
  font-size: 14px !important;
  line-height: 17px !important;
}

.fs-1 {
  line-height: calc(2.5rem * 125 / 100) !important;
}

.fs-2 {
  line-height: calc(2rem * 125 / 100) !important;
}

.fs-3 {
  line-height: calc(1.75rem * 125 / 100) !important;
}

.fs-4 {
  line-height: calc(1.5rem * 125 / 100) !important;
}

.fs-5 {
  line-height: calc(1.25rem * 125 / 100) !important;
}
// fs-16
.fs-6 {
  line-height: calc(1rem * 125 / 100) !important;
}

.fs-7 {
  line-height: calc(0.75rem * 125 / 100) !important;
  font-size: 0.75rem !important;
}

.fs-8 {
  font-size: 0.5rem !important;
  line-height: calc(0.5rem * 125 / 100) !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.text {
  &-dark-600 {
    color: rgba(0, 0, 0, 0.6);
  }
  &-dark-400 {
    color: rgba(0, 0, 0, 0.4);
  }
  &-link {
    color: #4285f4;
  }
}

a {
  &:hover {
    color: #1a25ab !important;
  }
}

.separator-line {
  display: block;
  width: 1px;
  height: 1px;
  &-dark-200 {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.wrapper {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.btn-circle {
  width: calc(2.40625rem + 2px);
  height: calc(2.40625rem + 2px);
}

button {
  font-weight: 400;
  &.bg-primary {
    border: solid 1px var(--primary) !important;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  &.bg-primary:not([disabled]) {
    &:hover {
      background-color: var(--primary-hover) !important;
    }
  }
  &.cancel {
    background-color: var(--cancel) !important;
    &:hover {
      opacity: 0.8;
    }
  }
  &.btn-apply {
    border: solid 1px var(--primary) !important;
    background-color: var(--primary) !important;
    // text-transform: uppercase;
    &:hover {
      background-color: var(--primary-hover) !important;
      color: #fff !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
  &.btn-cancel {
    border: solid 1px var(--primary) !important;
    background: transparent !important;
    color: var(--primary) !important;
    &:hover {
      background-color: var(--primary-hover) !important;
      color: #fff !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
  &:hover {
    background-color: var(--primary-hover);
    color: #fff;
  }
  &.swal2-close:focus {
    box-shadow: none !important;
  }

  &.swal2-styled.swal2-confirm:focus {
    box-shadow: none !important;
  }

  &.swal2-styled.swal2-cancel:focus {
    box-shadow: none !important;
  }
}

.btn {
  &:focus-visible {
    box-shadow: none;
  }
}

.vertical-middle {
  vertical-align: middle;
}

.white-space-pre {
  white-space: pre;
}

.cursor-pointer {
  cursor: pointer;
}

.link {
  color: #4285f4;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  transition: color 0.2s linear;
  cursor: pointer;
  will-change: color;

  &:hover {
    color: var(--primary-hover) !important;
  }

  &:active {
    color: var(--primary);
  }

  &.disabled {
    color: #90baff;
    cursor: default;
    pointer-events: none;
    user-select: none;
  }
}

.loading-icon {
  animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

// Input when auto fill will background white
input:autofill,
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

:where(select) {
  background-color: var(--white);
}

@media screen and (max-width: 1024px) {
  $spacer: 0.75rem;
}
