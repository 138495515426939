.ticket-detail {
  height: 700px;

  select:not(.select) {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: url('../../../../app/images/arrow-down-600.svg') no-repeat right center;
    cursor: pointer;

    &.quick-answer {
      width: 140px;
    }
    &.status {
      width: 90px;
    }
  }

  .update-ticket {
    height: 43px;
  }

  .info-body {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 20px 44px;
    width: fit-content;
    align-items: center;
  }

  .product-list {
    .product-item {
      border-bottom: 0.5px dashed rgba(0, 0, 0, 0.3);
      &:first-child {
        padding-top: 0 !important;
      }

      &:last-child {
        padding-bottom: 0 !important;
        border-bottom: unset;
      }
    }
  }

  //   Help desk
  .help-desk {
    overflow: hidden;
  }

  .help-desk-heading {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .left {
      p {
        font-size: 20px;
        line-height: 24px;
      }

      svg {
        font-size: 22px;
      }
    }
  }

  .help-desk-body {
    overflow: auto;
  }

  .fs-13px {
    font-size: 13px;
  }

  .help-desk-message {
    width: 100%;

    .image-attachment {
      max-width: min(400px, calc(100% - 24px));
      border-radius: 5px;
      object-fit: contain;
    }

    &.admin {
      margin-left: auto;
      flex-direction: row-reverse;

      .mess-info {
        text-align: end;
      }

      .mess-heading {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
      }

      .mess {
        margin-left: auto;
        background: #f5f7fb;
        border-radius: 20px 0px 20px 20px;
      }
    }

    .mess-heading {
      max-width: 100%;

      .time-at {
        font-size: 12px;
        line-height: 17px;
      }

      > * {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .mess {
      a {
        word-break: break-all;
      }
    }

    .mess-info {
      width: calc(100% - 40px);
    }

    .avatar {
      $size: 25px;
      width: $size;
      height: $size;
      object-fit: cover;
      flex-shrink: 0;
      border-radius: 50%;

      &:not(.no-avatar) {
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
      }

      &.no-avatar {
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

        svg {
          font-size: 12px;
        }
      }
    }

    .mess {
      background: #ebf1fd;
      border-radius: 0 20px 20px 20px;
      width: 100%;
    }
  }

  .help-desk-footer {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    .heading {
      margin-bottom: 10px;

      .actions {
        .add-link {
          transition: color 0.2s linear;

          &:hover {
            color: var(--primary-hover);
          }
        }

        > * {
          padding-left: 16px;
          margin-right: 16px;
          border: transparent;
          outline: none;
          border-left: 1px solid rgba(0, 0, 0, 0.3);

          &:first-child {
            padding-left: 0;
            border-left: none;
          }

          &:last-child {
            margin-right: 0px;
          }
        }

        svg.arrow-down {
          position: relative;
          top: -3px;
        }
      }
    }

    .footer {
      .attachment {
        background-color: #d8d7d7;
        display: inline-block;
      }

      .plane-icon {
        $size: 40px;
        width: $size;
        height: $size;
        flex-shrink: 0;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        background-color: #fff;
        transition: background-color 0.2s linear, box-shadow 0.2s linear;

        &:disabled {
          opacity: 0.6;
          cursor: default;
          background-color: #fff;
          user-select: none;
          pointer-events: none;

          svg {
            color: rgba(0, 0, 0, 0.6);
          }

          &:hover {
            background-color: none !important;
          }
        }

        &:hover {
          background-color: #f5f7fb;

          svg {
            color: black;
          }
        }

        &:active {
          background: var(--white);
          box-shadow: 0px 0px 0px 2px rgba(24, 7, 103, 0.1);
        }

        &:focus {
          border: 1px solid #180767;
          background: var(--white);
          box-shadow: 0px 0px 0px 2px rgba(24, 7, 103, 0.1);
        }
      }

      .message {
        caret-color: var(--primary);
        outline: none;
        border: none;
        cursor: auto;
        width: 90%;
        padding-right: 16px;
        max-height: 140px;
        overflow: auto;

        &:empty:before {
          user-select: none;
          pointer-events: none;
          color: rgba(0, 0, 0, 0.6);
          content: attr(data-placeholder);
        }
      }
    }
  }

  .close-icon {
    transition: color 0.2s linear;

    &:hover {
      color: var(--danger);
    }
  }

  // Popup
  .content-popup {
    border-radius: 10px;
    width: 448px;
    max-width: 100%;
    max-height: calc(100vh - 24px);
    overflow: auto;

    .nav {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #d9d9d9;
      }

      .nav-item {
        border-bottom: 2px solid transparent;
        z-index: 1;
        color: rgba(0, 0, 0, 0.6);

        &.active {
          color: var(--primary);
          border-color: var(--primary);
        }
      }
    }

    .note-add-quick-answer {
      font-size: 12px;
      line-height: 18px;
    }

    .heading {
      .title {
        font-size: 24px;
        line-height: 36px;
      }
    }

    .footer {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 16px;
    }

    .select-active {
      height: 46px;
    }

    .content-quick-answer {
      resize: none;
      height: 150px;
      overflow: auto;
      transition: all 0.2s linear;

      &:focus {
        box-shadow: none;
        border-color: var(--primary);
      }
    }
  }
}

@media screen and (min-width: 1441px) and (max-width: 1471px) {
  .ticket-detail {
    .info-body {
      gap: 20px;
      .status-select {
        padding: 12px 28px 12px 16px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .ticket-detail {
    .info-body {
      gap: 20px 5px;
    }
    .help-desk-message {
      &.admin {
        .mess-heading {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
      &.customer {
        .mess-heading {
          display: flex;
          flex-direction: column;
          align-items: flex-start !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .ticket-detail {
    height: auto;

    .product-list {
      max-height: 120px;
      overflow: auto;
    }

    select {
      &.quick-answer {
        width: 125px;
      }
      &.status {
        width: 75px;
      }
    }

    .info-body {
      gap: 16px 28px;
    }

    .help-desk-message {
      &.admin {
        .mess-heading {
          align-items: flex-end !important;
        }
      }

      .mess-heading {
        flex-direction: column !important;
        row-gap: 4px !important;
        text-align: start !important;
        align-items: unset !important;
      }
    }

    //   Product list
    .product-wrap {
      max-height: 400px;
    }

    // Help desk
    .help-desk {
      max-height: 700px;
    }
  }
}
