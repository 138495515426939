table {
  .table-w10 {
    width: 10%;
    white-space: normal;
  }
  thead {
    tr {
      td {
        border-right: 1px solid #bebebe;
        &.thead-item {
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
          --bs-text-opacity: 1;
          color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  tbody {
    td {
      &.td-item {
        vertical-align: middle;
        border-right: 1px dashed rgba(0, 0, 0, 0.2);
        padding: 24px 0;
        font-size: 14px;
        line-height: 17px;
        background-color: transparent;
      }
      .product-image {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      .table-info {
        p {
          font-size: 12px;
          line-height: 17px;
          color: var(--primary);
          white-space: normal;
          font-weight: 600;
        }

        span {
          font-size: 12px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}

.order-table {
  tbody {
    tr:nth-child(2n) {
      background-color: #f5f9fe;
    }
    td:last-child {
      border-right: none;
    }
  }
}
