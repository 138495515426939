@import 'override';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  background-color: rgba(245, 247, 251, 1);
  overflow-y: auto;
  overflow-y: overlay;
}

// my own spacer's map
:root {
  --bs-body-font-family: 'Poppins', sans-serif;
  --bs-gradient: none;
  --bs-body-color: #{$dark};
  --dark: #{$dark};
  --white: #{$white};
  --primary: #{$primary};
  --secondary: #{$secondary};
  --info: #{$info};
  --danger: #{$danger};
  --warning: #{$warning};
  --success: #{$success};
  --thead: #{$thead};
  --primary-hover: #{$primary-hover};
  --disabled-color: #{$disabled};
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 15px;
}
::-webkit-scrollbar:horizontal {
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: rgb(197, 197, 197);
  border-radius: 10px;
}
