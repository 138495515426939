.btn-square-light {
  $size: 35px;
  width: $size;
  height: $size;
  &:hover {
    background-color: #1a25ab !important;
    border-color: #1a25ab !important;
  }
}

.btn {
  &.btn-primary {
    &:hover {
      color: #fff !important;
      background-color: #1a25ab;
      border-color: #1a25ab;
    }
  }
  &.btn-update-password {
    font-size: 14px;
  }
  &.btn-update-profile {
    font-size: 14px;
  }
}

.form-control {
  &.form-control-solid {
    padding: 0.575rem 0.75rem;
    border-radius: 5px;
    border-color: #00000033;
    color: #5e6278;
    transition: all 0.2s ease;

    &.is-invalid:focus {
      box-shadow: none;
    }
    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #8080c0;
      outline: 0;
      box-shadow: none;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px white inset !important;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
    }

    &:focus {
      border-color: var(--primary) !important;
    }
  }

  &.form-control--description {
    resize: none;
    height: 200px;
  }
}

.border-upload-img {
  border: 1px dashed #000080;
  border-radius: 10px;
}

.btn-upload-img {
  $size: 40px;
  width: $size;
  height: $size;
  left: 85%;
  bottom: 35px;
  background-color: #fff !important;
  &:hover {
    background-color: #1a25ab !important;
    border-color: #1a25ab !important;
  }
}

.flex-row-fluid {
  flex: 1 1 auto;
  min-width: 0;
}
.is-invalid + .show-pwd,
.is-invalid + .hide-pwd {
  right: 45px;
}
.show-pwd,
.hide-pwd {
  position: absolute;
  top: 12px;
  right: 25px;
}

.btn.btn-cancel-password {
  color: #4285f4;
  &:hover {
    color: #1a25ab;
  }
}
