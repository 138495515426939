.profile-left {
  .profile__banner {
    height: 250px;
    background-color: #dcdddf;
    position: relative;

    .profile__banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .profile__camera {
      position: absolute;
      bottom: 24px;
      right: 24px;
    }

    .close-icon {
      position: absolute;
      top: 24px;
      right: 24px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s linear;
    }

    &:hover .close-icon {
      opacity: 1;
      visibility: visible;
    }
  }

  .profile__avatar {
    $size: 200px;
    margin-top: calc($size / -2);

    .profile__avatar-rounded {
      width: $size;
      height: $size;
      border-radius: 50%;
      position: relative;
      background-color: #dcdddf;

      .profile__avatar-image {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        object-fit: cover;
      }

      .profile__camera {
        position: absolute;
        bottom: 0;
        right: 24px;
      }
    }

    .close-icon {
      position: absolute;
      top: 40px;
      right: 40px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s linear;
    }

    &:hover .close-icon {
      opacity: 1;
      visibility: visible;
    }
  }

  .profile__fullname-desc {
    margin: 0 40px;

    .profile__fullname {
      width: 100%;
      overflow: hidden;
      max-width: 425px;
      text-align: center;
    }

    .profile__description {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      text-align: center;
      max-width: 425px;
      white-space: pre-line;
    }
  }

  .profile__info {
    padding: 0 40px;
    max-width: calc(500px + 80px);
    width: 100%;

    .profile__info-list {
      margin: 0;
      padding: 0;
    }

    .profile__info-item {
      list-style-type: none;
    }

    .profile__info-icon {
      width: 32px;
      flex-shrink: 0;
    }
  }

  .profile__actions {
    padding: 0 40px;

    .profile__actions-save {
      max-width: 100%;
      height: 44px;
    }

    .profile__actions-remove {
      .separator {
        height: 100%;
        width: 1px;
        margin-inline: 12px;
        background-color: #dbdbdb;
      }
    }
  }

  .profile__camera {
    $size: 30px;
    width: $size;
    height: $size;
    border: 1.5625px solid rgba(0, 0, 0, 0.6);
    background-color: var(--white);
    border-radius: 50%;
    flex-shrink: 0;
    cursor: pointer;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: #dcdddf;
    }

    img {
      object-fit: contain;
    }
  }

  .profile__social-item {
    $size: 20px;
    width: $size;
    height: $size;
    list-style-type: none;

    .profile__social-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: transparent;
      transition: all 0.2s linear;
      flex-shrink: 0;
      border-radius: 50%;

      &:hover {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        transform: scale(1.15);
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .profile-left {
    .profile__info {
      margin: 0 auto;
    }
  }
}
