#create-edit__product .configurations {
  .describe {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  // .pagination-configuration {
  //   .item-per-pages {
  //     background-color: #f5f7fb !important;
  //   }
  // }

  .form-control-custom-search-input {
    max-width: 150px !important;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .attr-name {
    max-width: 500px;
  }

  .list-child-attr {
    display: grid;
    grid-template-columns: repeat(4, 250px);
    gap: 24px;
    flex-grow: 1;
    border: 1px solid rgba($color: #000000, $alpha: 0.08);
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
  }

  .error-message {
    max-width: 200px;
  }

  .mess-err {
    max-width: 160px;
  }

  .heading {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: var(--white);
    padding-top: 30px;
    padding-inline: 30px;
  }

  .name-product,
  .sku-product {
    min-width: 100px;
  }

  .table-head {
    position: sticky;
    top: 100px;
    z-index: 99;
  }

  .table-body {
    overflow-y: scroll;
  }

  .config-wrap {
    padding-inline: 30px;
    padding-bottom: 40px;

    &.popup-custom-class {
      padding-bottom: 88px;
    }
  }

  .close-icon {
    font-size: 26px;
    transition: color 0.2s linear;

    &:hover {
      color: var(--danger);
    }
  }

  .btn-close {
    content: '';
    border: none;
    appearance: none;
    background: transparent;
    outline: none;

    &:focus {
      box-shadow: none;
    }
  }

  .content-step1 {
    display: grid;
    max-width: 1200px;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 24px;
  }

  .attr-value {
    grid-column: 1 / span 2;
  }

  .image-wrap {
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  #table-config {
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);

    input[name^='name'],
    input[name^='sku'] {
      min-width: 120px;
    }

    input[name^='price'] {
      min-width: 80px;
    }

    input {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      box-shadow: none;
      transition: border-color 0.2s linear;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }

      &:focus {
        border-color: var(--primary);
      }
    }

    .thumbnail {
      overflow: hidden;
      position: relative;

      img {
        $size: 60px;
        width: $size;
        height: $size;
        object-fit: cover;
      }
    }

    thead tr {
      th:first-child {
        border-top-left-radius: 5px;
      }
      th:last-child {
        border-top-right-radius: 5px;
      }
    }

    tbody tr:nth-child(even) {
      background-color: #f5f9fe;
    }

    tr {
      th:first-child,
      td:first-child {
        padding-inline: 24px !important;
      }
      th:last-child,
      td:last-child {
        padding-inline: 24px !important;
      }
    }

    th,
    td {
      font-size: 14px;
      line-height: 17px;
      padding-block: 16px;
      padding-inline: 8px;

      &:last-child {
        border-right: none !important;
      }
    }

    th {
      font-weight: 500;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0px;
        width: 0.6px;
        height: 100%;
        z-index: 1;
        transform: translateX(25%);
        background-color: #bebebe;
      }

      &:last-child::before {
        width: 0;
      }
    }

    td {
      border: none;
      vertical-align: top;
      border-right: 1px dashed rgba(0, 0, 0, 0.2);
    }
  }

  .image {
    $size: 60px;
    width: $size;
    height: $size;
  }

  .trash {
    font-size: 22px;
    transition: color 0.2s linear;

    &:hover {
      color: var(--danger);
    }
  }

  .list-attr {
    text-align: end;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 16px;
    width: fit-content;

    .name-attr {
      min-width: 180px;
      max-width: 400px;
    }

    .select {
      min-width: 180px;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .btn-add {
    width: fit-content;

    h3 {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    div {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid #dbdbdb;
    }

    .options {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 1;
      background-color: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

      h3 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &:last-child {
          border: none;
        }
        &:hover {
          color: var(--primary) !important;
          background-color: #e5f4fc;
        }
      }
    }
  }

  .btn-done {
    position: absolute;
    bottom: 40px;
    right: 30px;
  }
}

@media (max-width: 1440px) {
  #create-edit__product .configurations {
    .list-attr {
      .select {
        max-width: 240px;
        font-size: 14px !important;
      }
    }
  }
}

@media (max-width: 1280px) {
  #create-edit__product .configurations {
    .list-attr {
      .select {
        max-width: 180px;
      }
    }
  }
}

@media (max-width: 1200px) {
  #create-edit__product .configurations {
    .list-child-attr {
      grid-template-columns: repeat(3, 250px);
    }

    .list-attr {
      grid-template-columns: auto;
      text-align: start;
      row-gap: 8px;

      .select {
        max-width: 180px;
      }
    }

    #product-generate-table {
      th {
        font-size: 12px;
        line-height: 15px;
      }
    }

    .trash {
      font-size: 16px;
      transition: color 0.2s linear;

      &:hover {
        color: var(--danger);
      }
    }
  }
}
